<template>
  <div class="tap5">
    <div class="header-title">
      <h1 class="title">Banner</h1>
      <b-button class="btn-filter" @click="openSideBarBanner">
        Create
      </b-button>
    </div>
    <div class="sec1">
      <Table
        :items="items"
        :fields="fields"
        :isBusy="isBusy"
        :rows="rows"
        :filter="filter"
        :pageOptions="pageOptions"
        @filterPage="filterPage"
        @editBanner="editBanner"
        @removeBanner="openModalConfirm($event, 'banner')"
      />
      <SideBarFilter
        :filter="filter"
        ref="sidebarBanner"
        placeholder="Transaction"
        :hideStatusFilter="false"
        :hideSearchBar="true"
        title="Event Banner info"
        textSubmit="Create"
        @searchAll="onAddBanner"
        handleSideBar
        @clearForm="clearForm"
        hideFilter
      >
        <!-- @clearFilter="clearFilter" -->
        <template v-slot:filter-option>
          <div class="mt-3 mb-3">
            <UploadFile
              textFloat="Profile picture"
              placeholder="Please Choose File"
              format="image"
              name="file"
              text="*Please upload only .png .jpg image width ratio 16:10 less than 10 MB"
              accept="image/*"
              id="uploadfile"
              required="Please select image."
              isRequired
              @onFileChange="onFileChange"
              :fileName="form.fileName"
              v-model="form.base64.base64"
              :v="$v.form.base64.base64"
            />
          </div>
          <div
            class="render-image"
            :style="`background-image:url(${form.base64.base64})`"
          ></div>
          <div class="mt-3 mb-3">
            <InputText
              v-model="form.sortOrder"
              class="input"
              textFloat="Sort Order"
              placeholder="0"
              type="number"
              name="number"
              isRequired
              :isValidate="$v.form.sortOrder.$error"
              :v="$v.form.sortOrder"
            />
          </div>
          <div class="mt-3 mb-3">
            <b-form-checkbox
              v-model="form.isDeleted"
              switch
              :value="0"
              :unchecked-value="1"
            >
              Active
            </b-form-checkbox>
          </div>
        </template>
      </SideBarFilter>
    </div>
  </div>
</template>

<script>
import Table from "./tap5/Table.vue";
import UploadFile from "@/components/inputs/UploadFile";

import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Table,
    UploadFile,
  },
  data() {
    return {
      // state
      form: {
        fileName: null,
        isDeleted: 0,
        sortOrder: null,
        isVideo: 0,
        base64: {
          base64: null,
        },
      },

      // state table zone
      items: [],
      filter: {
        filter: null,
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      },
      fields: [
        {
          key: "imgShowUrl",
          label: "Image",
          class: "w-50px text-nowrap",
        },
        {
          key: "sortOrder",
          label: "Sort Order",
          class: "w-100px text-nowrap",
        },
        {
          key: "isDeleted",
          label: "Status",
          class: "w-100px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },

      // state modal
      textModal: "",

      // state delete
      keepRemove: null,
    };
  },
  validations() {
    return {
      form: {
        fileName: { required },
        sortOrder: { required },
        base64: {
          base64: { required },
        },
      },
    };
  },

  mounted() {
    this.getGallery();
  },
  methods: {
    async getGallery() {
      const getData = await this.$services.event.getGallery(
        this.$route.params.id,
        this.filter.skip,
        this.filter.take
      );

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;
      }
    },
    async editBanner(obj) {
      let { eventId, id } = obj;
      const getData = await this.$services.event.getEventGalleryById(
        eventId,
        id
      );

      if (getData.result == 1) {
        let { imgUrl, isDeleted, sortOrder, isVideo, imgShowUrl, id, eventId } =
          getData.detail;

        this.form = {
          id,
          eventId,
          imgUrl,
          fileName: imgUrl.split("/").reverse()[0],
          isDeleted: isDeleted == false ? 0 : 1,
          sortOrder: sortOrder,
          isVideo: isVideo == false ? 0 : 1,
          base64: {
            base64: imgShowUrl,
          },
        };

        this.openSideBarBanner();
      }
    },
    async updateEventGalleryById() {
      const newBody = {
        ...this.form,
      };

      delete newBody.eventId;
      delete newBody.id;
      delete newBody.fileName;

      // check image
      if (newBody.base64.base64.includes("https")) {
        newBody.base64 = null;
      }

      const update = await this.$services.event.updateEventGalleryById(
        this.form.eventId,
        this.form.id,
        newBody
      );

      if (update.result == 1) {
        this.getGallery();
        this.clearForm();

        // reset validate
        this.$v.form.$reset();

        this.closeSideBarBanner();
      }
      this.openModalAlertText(update.detail);

      this.closeSideBarBanner();
    },
    async removeBanner() {
      let { eventId, id } = this.keepRemove;

      const remove = await this.$services.event.removeEventGalleryById(
        eventId,
        id
      );

      if (remove.result == 1) {
        this.getGallery();
      }
      this.openModalAlertText(remove.detail);
    },
    async onAddBanner() {
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }

      // check edit

      if (this.form.eventId) {
        this.updateEventGalleryById();
        return;
      }

      const newbody = { ...this.form };

      delete newbody.fileName;

      const addbanner = await this.$services.event.insertEventGallery(
        this.$route.params.id,
        newbody
      );

      if (addbanner.result == 1) {
        this.getGallery();

        // clear form
        this.form = {
          fileName: null,
          isDeleted: 0,
          sortOrder: null,
          isVideo: 0,
          base64: {
            base64: null,
          },
        };

        // reset validate
        this.$v.form.$reset();
      }
      this.openModalAlertText(addbanner.detail);

      this.closeSideBarBanner();
    },

    openModalConfirm(obj, type) {
      this.keepRemove = obj;

      let data = {
        name: obj.imgUrl.split("/").reverse()[0],
        type,
      };

      this.$emit("openModalConfirm", data);
    },

    onFileChange(file) {
      this.form.fileName = file.name;

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (result) => {
        this.form.base64.base64 = result.target.result;
      };
    },

    clearForm() {
      this.form = {
        fileName: null,
        isDeleted: 0,
        sortOrder: null,
        isVideo: 0,
        base64: {
          base64: null,
        },
      };
    },

    filterPage(obj) {
      let { page } = obj;
      this.filter.skip = page;

      this.getGallery();
    },

    openSideBarBanner() {
      this.$refs.sidebarBanner.show();
    },

    closeSideBarBanner() {
      this.$refs.sidebarBanner.hide();
    },

    openModalAlertText(text) {
      this.$emit("openModalAlertText", text);
    },
  },
};
</script>

<style lang="scss" scoped>
.tap5 {
  background: white;
  padding: 15px;
  min-height: 60vh;

  .title {
    font-size: 18px;
  }

  .div-divide {
    border-bottom: 1px solid darkgray;
    padding: 10px 0px;
  }

  .header-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .render-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    // width: 300px;
    height: 200px;
    border: 1px dashed gray;
  }

  // .sec1 {
  // }
}

// ! Override
.btn-filter {
  width: 100%;
  max-width: 120px;
}
</style>
