<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(firstname)="{ item }">
            <p class="m-0">{{ item.firstname }} {{ item.lastname }}</p>
          </template>

          <template #cell(grandTotal)="{ item }">
            <p class="m-0">{{ formatNumber(item.grandTotal) }}</p>
          </template>

          <template #cell(bookingDate)="{ item }">
            <p class="m-0">
              {{ $moment(item.bookingDate).format("DD/MM/YYYY") }}
            </p>
            <!-- <p class="m-0 text-time">
              {{ `(${$moment(item.bookingDate).format("HH:mm")})` }}
            </p> -->
          </template>

          <template #cell(statusId)="{ item }">
            <p class="m-0" :style="`color:${item.color};`">
              {{ item.statusName }}
            </p>
          </template>

          <template v-slot:cell(actions)="{ item }">
            <div class="d-flex justify-content-center">
              <p class="m-0 underline" @click="goDetail(item)">Detail</p>
            </div>
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
  },
  methods: {
    goTransactionDetail(refId) {
      if (!refId) {
        return;
      }

      this.$router.push(`/transaction/details/${refId}`);
    },
    openModalConfirm(obj) {
      let { refId } = obj;

      this.$emit("getPaymentInfo", refId);
    },
    // handleDelete(item) {
    //   this.$emit("handleDelete", item);
    // },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    // colorClass(item) {
    //   if (item.status == "1") return "text-warning";
    //   else if (item.status == "2") return "text-success";
    //   else if (item.status == "3") return "text-danger";
    //   else return "text-secondary";
    // },
    // chkBirthday(birthday) {
    //   let birthDate = birthday.search("00:00:00");
    //   if (birthDate === -1) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
    goDetail(obj) {
      let { refId } = obj;
      this.$emit("goDetail", refId);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}

.color-red {
  color: #dc3545;
}

.color-green {
  color: #28a745;
}

.color-blue {
  color: lightcoral;
}

.text-time {
  opacity: 0.5;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.render-image {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 250px;
  height: 190px;
  margin: auto;
}
</style>
