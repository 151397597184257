<template>
  <div class="tap4">
    <div v-if="!isCustomerDetail">
      <div class="header-title">
        <h1 class="title">Customer</h1>
        <b-button class="btn-filter ml-2" @click="openSidebar">
          <span class="d-none d-md-block">
            <font-awesome-icon icon="filter" class="pointer" />
            <span class="btn-text">Filter</span>
          </span>
        </b-button>
      </div>
      <div class="sec1">
        <Table
          :items="items"
          :fields="fields"
          :isBusy="isBusy"
          :rows="rows"
          :filter="filter"
          :pageOptions="pageOptions"
          @filterPage="filterPage"
          @goDetail="goDetail"
        />

        <SideBarFilter
          :filter="filter"
          ref="sidebarCustomer"
          placeholder="Transaction"
          :hideStatusFilter="false"
          @searchAll="searchAll"
          @clearFilter="clearFilter"
        >
          <template v-slot:filter-option>
            <div class="mt-3 mb-3">
              <InputSelect
                v-model="configFilter.statusId"
                :options="optionsStatus"
                title="สถานะ"
                class="mt-2"
                valueField="transactionStatusId"
                textField="name"
              />
            </div>
            <div class="mt-3 mb-3">
              <InputSelect
                v-model="configFilter.paymentTypeId"
                :options="optionsPayment"
                title="ช่องทางการชำระเงิน"
                class="mt-2"
                valueField="paymentTypeId"
                textField="name"
              />
            </div>
            <div class="d-flex">
              <div class="mb-3">
                <div class="font-weight-bold mb-2">วันที่สร้างเริ่มต้น</div>
                <div :class="['input-container']">
                  <datetime
                    v-model="configFilter.createdTime.startTime"
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy"
                    value-zone="local"
                    placeholder="Please Select Date"
                    ref="createStartDate"
                    :max-datetime="maxDate"
                  >
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.createStartDate.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer g-text-gold"
                      color="#B41BB4"
                    />
                  </div>
                </div>
              </div>
              <div class="mx-1"></div>
              <div class="mb-3">
                <div class="font-weight-bold mb-2">วันที่สร้างสิ้นสุด</div>
                <div :class="['input-container']">
                  <datetime
                    v-model="configFilter.createdTime.endTime"
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy"
                    value-zone="local"
                    placeholder="Please Select Date"
                    ref="createEndDate"
                    :disabled="!configFilter.createdTime.startTime"
                    :max-datetime="maxDate"
                    :min-datetime="minDateCreate"
                  >
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.createEndDate.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer g-text-gold"
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <p
                  class="text-error"
                  v-if="$v.configFilter.createdTime.endTime.$error"
                >
                  กรุณาเลือก
                </p>
              </div>
            </div>

            <div class="d-flex">
              <div class="mb-3">
                <div class="font-weight-bold mb-2">วันที่ชำระเงินเริ่มต้น</div>
                <div :class="['input-container']">
                  <datetime
                    v-model="configFilter.paymentTime.startTime"
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy"
                    value-zone="local"
                    placeholder="Please Select Date"
                    ref="paymentStart"
                    :max-datetime="maxDate"
                  >
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.paymentStart.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer g-text-gold"
                      color="#B41BB4"
                    />
                  </div>
                </div>
              </div>
              <div class="mx-1"></div>
              <div class="mb-3">
                <div class="font-weight-bold mb-2">วันที่ชำระเงินสิ้นสุด</div>
                <div :class="['input-container']">
                  <datetime
                    v-model="configFilter.paymentTime.endTime"
                    :input-style="styleDatetime"
                    format="dd/MM/yyyy"
                    value-zone="local"
                    placeholder="Please Select Date"
                    ref="paymentEndDate"
                    :disabled="!configFilter.paymentTime.startTime"
                    :max-datetime="maxDate"
                    :min-datetime="minDatePayment"
                  >
                  </datetime>
                  <div
                    class="icon-primary text-right"
                    @click="$refs.paymentEndDate.isOpen = true"
                  >
                    <font-awesome-icon
                      icon="calendar-alt"
                      class="pointer g-text-gold"
                      color="#B41BB4"
                    />
                  </div>
                </div>
                <p
                  class="text-error"
                  v-if="$v.configFilter.paymentTime.endTime.$error"
                >
                  กรุณาเลือก
                </p>
              </div>
            </div>

            <div class="mb-3">
              <div class="font-weight-bold mb-2">รอบวันที่ + เวลาเริ่มต้น</div>
              <div :class="['input-container']">
                <datetime
                  type="datetime"
                  v-model="configFilter.bookingDate.startTime"
                  :input-style="styleDatetime"
                  format="dd/MM/yyyy HH:mm:ss"
                  value-zone="Asia/Bangkok"
                  placeholder="Please Select Date"
                  ref="bookingDateStart"
                  :phrases="{ ok: 'Continue', cancel: 'Exit' }"
                  use24-hour
                >
                </datetime>
                <div
                  class="icon-primary text-right"
                  @click="$refs.bookingDateStart.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="pointer g-text-gold"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </div>
            <div class="mx-1"></div>
            <div class="mb-3">
              <div class="font-weight-bold mb-2">รอบวันที่ + เวลาสิ้นสุด</div>
              <div :class="['input-container']">
                <datetime
                  type="datetime"
                  v-model="configFilter.bookingDate.endTime"
                  :input-style="styleDatetime"
                  format="dd/MM/yyyy HH:mm:ss"
                  value-zone="Asia/Bangkok"
                  placeholder="Please Select Date"
                  ref="bookingDateEndDate"
                  :disabled="!configFilter.bookingDate.startTime"
                  :min-datetime="minDateBooking"
                  :phrases="{ ok: 'Continue', cancel: 'Exit' }"
                  use24-hour
                >
                </datetime>
                <div
                  class="icon-primary text-right"
                  @click="$refs.bookingDateEndDate.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="pointer g-text-gold"
                    color="#B41BB4"
                  />
                </div>
              </div>
              <p
                class="text-error"
                v-if="$v.configFilter.bookingDate.endTime.$error"
              >
                กรุณาเลือก
              </p>
            </div>
          </template>
        </SideBarFilter>
      </div>
    </div>
    <div v-else>
      <CustomerDetail :refId="refId" @goCustomerDetail="goCustomerDetail" />
    </div>
  </div>
</template>

<script>
import Table from "./tap4/Table.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import CustomerDetail from "../component/tap4/CustomerDetail.vue";

export default {
  components: {
    Table,
    CustomerDetail,
  },
  data() {
    return {
      // state

      filter: {
        filter: null,
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      },

      configFilter: {
        statusId: null,
        paymentTypeId: null,
        eventId: "",
        createdTime: {
          startTime: null,
          endTime: null,
        },
        paymentTime: {
          startTime: null,
          endTime: null,
        },
        bookingDate: {
          startTime: null,
          endTime: null,
        },
      },

      // options filter
      optionsStatus: [],
      optionsPayment: [],
      optionsEvent: [],

      // condition date
      maxDate: null,

      minDateCreate: null,
      minDatePayment: null,
      minDateBooking: null,

      // state table zone
      items: [],
      filter: {
        filter: {
          eventId: null,
        },
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      },
      fields: [
        {
          key: "firstname",
          label: "Customer Name",
          class: "w-50px text-nowrap",
        },
        {
          key: "grandTotal",
          label: "Grand Total",
          class: "w-50px text-nowrap",
        },
        {
          key: "bookingDate",
          label: "Booking Datetime",
          class: "w-50px text-nowrap",
        },
        {
          key: "statusId",
          label: "Status",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Detail", class: "w-50px text-nowrap" },
      ],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },

      // state customer detail
      isCustomerDetail: false,
      refId: null,
    };
  },
  validations() {
    return {
      configFilter: {
        createdTime: {
          endTime: {
            required: requiredIf(() => {
              return this.configFilter.createdTime.startTime;
            }),
          },
        },
        paymentTime: {
          endTime: {
            required: requiredIf(() => {
              return this.configFilter.paymentTime.startTime;
            }),
          },
        },
        bookingDate: {
          endTime: {
            required: requiredIf(() => {
              return this.configFilter.bookingDate.startTime;
            }),
          },
        },
      },
    };
  },
  watch: {
    "configFilter.createdTime.startTime"(val) {
      if (val) {
        this.minDateCreate = new Date(val).toISOString();

        // clear endtime
        this.configFilter.createdTime.endTime = null;
      }
    },
    "configFilter.paymentTime.startTime"(val) {
      if (val) {
        this.minDatePayment = new Date(val).toISOString();

        // clear endtime
        this.configFilter.paymentTime.endTime = null;
      }
    },
    "configFilter.bookingDate.startTime"(val) {
      if (val) {
        this.minDateBooking = new Date(val).toISOString();

        // clear endtime
        this.configFilter.bookingDate.endTime = null;
      }
    },
  },
  mounted() {
    this.getCustomer();

    // data sidebar
    this.getStatusOption();
    this.getPaymentOption();

    // condition date
    this.maxDate = new Date().toISOString();
  },
  methods: {
    async getCustomer() {
      this.filter.filter.eventId = this.$route.params.id;

      const getData = await this.$services.transaction.getTransactionList(
        this.filter
      );

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;
      }
    },
    async getStatusOption() {
      const getData = await this.$services.master.getStatusOption();

      if (getData.result == 1) {
        this.optionsStatus = [
          {
            name: "All",
            transactionStatusId: null,
          },
          ...getData.detail,
        ];
      }
    },
    async getPaymentOption() {
      const getData = await this.$services.master.getPaymentOption();

      if (getData.result == 1) {
        this.optionsPayment = [
          {
            name: "All",
            transactionStatusId: null,
          },
          ...getData.detail,
        ];
      }
    },
    goDetail(refId) {
      this.refId = refId;
      this.isCustomerDetail = true;
    },
    goCustomerDetail() {
      this.refId = null;
      this.isCustomerDetail = false;
    },
    searchAll(filter) {
      let { statusId, paymentTypeId, createdTime, paymentTime, bookingDate } =
        this.configFilter;

      let { search } = filter;

      let { eventId } = filter.filter;

      let finalFilter = {
        filter: null,
        keyword: search ? search : "",
        page: 1,
        skip: 1,
        take: 10,
      };

      // validate filter

      this.$v.configFilter.$touch();

      if (this.$v.configFilter.$error) {
        return;
      }

      this.$refs.sidebarCustomer.hide();

      // add value to filter

      let newConfigFilter = {};

      statusId && (newConfigFilter.statusId = statusId);
      paymentTypeId && (newConfigFilter.paymentTypeId = paymentTypeId);
      eventId && (newConfigFilter.eventId = Number(eventId));

      if (createdTime.startTime && createdTime.endTime) {
        newConfigFilter.createdTime = {
          startTime: null,
          endTime: null,
        };

        newConfigFilter.createdTime.startTime = createdTime.startTime;
        newConfigFilter.createdTime.endTime = createdTime.endTime;
      }

      if (paymentTime.startTime && paymentTime.endTime) {
        newConfigFilter.paymentTime = {
          startTime: null,
          endTime: null,
        };

        newConfigFilter.paymentTime.startTime = paymentTime.startTime;
        newConfigFilter.paymentTime.endTime = paymentTime.endTime;
      }

      if (bookingDate.startTime && bookingDate.endTime) {
        newConfigFilter.bookingDate = {
          startTime: null,
          endTime: null,
        };

        newConfigFilter.bookingDate.startTime = bookingDate.startTime;
        newConfigFilter.bookingDate.endTime = bookingDate.endTime;
      }

      const isObjEmpty = Object.keys(newConfigFilter).length;

      if (isObjEmpty > 0) {
        finalFilter.filter = newConfigFilter;
      }

      this.filter = finalFilter;

      this.getCustomer();
    },
    clearFilter() {
      // clear validate
      this.$v.configFilter.$reset();

      this.filter = {
        filter: {
          eventId: null,
        },
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      };

      this.configFilter = {
        statusId: null,
        paymentTypeId: null,
        eventId: "",
        createdTime: {
          startTime: null,
          endTime: null,
        },
        paymentTime: {
          startTime: null,
          endTime: null,
        },
        bookingDate: {
          startTime: null,
          endTime: null,
        },
      };

      this.getCustomer();
      this.$refs.sidebarCustomer.hide();
    },
    filterPage(obj) {
      let { page } = obj;
      this.filter.skip = page;

      this.getCustomer();
    },
    openSidebar() {
      this.$refs.sidebarCustomer.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.tap4 {
  background: white;
  padding: 15px;
  min-height: 60vh;

  .title {
    font-size: 18px;
  }

  .div-divide {
    border-bottom: 1px solid darkgray;
    padding: 10px 0px;
  }

  .header-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  }

  //   .sec1 {
  //   }
}

// ! Override
.btn-filter {
  width: 100%;
  max-width: 120px;
}
</style>
