<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(price)="{ item }">
            <p class="m-0">
              {{ formatNumber(item.price) }}
            </p>
          </template>

          <template #cell(isDeleted)="{ item }">
            <p
              class="m-0"
              :class="item.isDeleted == 0 ? 'color-green' : 'color-red'"
            >
              {{ item.isDeleted == 0 ? "Active" : "Inactive" }}
            </p>
          </template>

          <template v-slot:cell(actions)="{ item }">
            <font-awesome-icon
              class="mr-4 pointer"
              icon="pen"
              @click="editTicket(item)"
            />
            <font-awesome-icon
              v-if="previewOrPublish == 0"
              class="pointer"
              icon="trash-alt"
              @click="removeTicket(item)"
            />
          </template>

          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: { Pagination },
  props: {
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    previewOrPublish: {
      type: Number,
      require: true,
    },
  },
  methods: {
    goTransactionDetail(refId) {
      if (!refId) {
        return;
      }

      this.$router.push(`/transaction/details/${refId}`);
    },
    openModalConfirm(obj) {
      let { refId } = obj;

      this.$emit("getPaymentInfo", refId);
    },
    // handleDelete(item) {
    //   this.$emit("handleDelete", item);
    // },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
    },
    editTicket(obj) {
      this.$emit("editTicket", obj);
    },
    removeTicket(obj) {
      this.$emit("removeTicket", obj);
    },
    formatNumber(val) {
      return this.$services.utils.formatNumber(val);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
.color-red {
  color: #dc3545;
}
.color-green {
  color: #28a745;
}
.color-blue {
  color: lightcoral;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}
</style>
