<template>
  <div>
    <b-modal
      id="modal-confirm-delete"
      body-class="p-0"
      size="md"
      hide-header
      hide-footer
      centered
    >
      <div class="confirm-delete">
        <div class="body">
          <p v-if="text === ''">ท่านต้องการลบ {{ nameItem }} ใช่หรือไม่ ?</p>
          <p v-else>{{ text }}</p>
        </div>
        <div class="footer-delete">
          <b-button class="btn-filter" @click="closeModal">ยกเลิก</b-button>
          <div class="mx-1"></div>
          <b-button class="btn-filter delete" @click="confirm">
            ยืนยัน
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    nameItem: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
    },
    closeModal() {
      this.$bvModal.hide("modal-confirm-delete");
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-delete {
  padding: 20px;

  .body {
    text-align: center;

    p {
      font-size: 18px;
      margin: 0px;
    }
  }

  .footer-delete {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}

// ! override
.btn-filter {
  width: 100%;
}
</style>
