<template>
  <div class="tap2">
    <div class="header-title">
      <h1 class="title">Zone</h1>
      <b-button class="btn-filter" @click="openSideBarZone"> Create </b-button>
    </div>
    <div class="sec1">
      <TableZone
        :items="items"
        :fields="fields"
        :isBusy="isBusy"
        :rows="rows"
        :filter="filter"
        :pageOptions="pageOptions"
        :previewOrPublish="previewOrPublish"
        @filterPage="filterPage"
        @editZone="editZone"
        @removeZone="openModalConfirm($event, 'zone')"
      />
      <SideBarFilter
        ref="sidebarZone"
        title="Zone"
        :filter="filter"
        :hideStatusFilter="false"
        :hideSearchBar="true"
        :textSubmit="form?.id ? 'Update' : 'Create'"
        @searchAll="insertEventZone"
        @clearForm="clearForm"
        handleSideBar
        hideFilter
        :isLoading="isLoading"
        @clearLoading="isLoading = false"
      >
        <!-- 
        @clearFilter="clearFilter" -->
        <template v-slot:filter-option>
          <div class="mt-3 mb-3">
            <InputText
              v-model="form.name"
              class="input"
              textFloat="Name"
              placeholder="Name"
              type="text"
              name="text"
              isRequired
              :isValidate="$v.form.name.$error"
              :v="$v.form.name"
            />
          </div>
          <div class="mt-3 mb-3">
            <InputText
              v-model="form.quantity"
              class="input"
              textFloat="Quantity"
              placeholder="0"
              type="number"
              name="number"
              isRequired
              :isValidate="$v.form.quantity.$error"
              :v="$v.form.quantity"
            />
          </div>
          <!-- <div class="mt-3 mb-3">
            <b-form-checkbox
              v-model="form.isActive"
              switch
              :value="1"
              :unchecked-value="0"
            >
              Active
            </b-form-checkbox>
          </div> -->
        </template>
      </SideBarFilter>
    </div>
    <div class="div-divide"></div>
    <div class="header-title">
      <h1 class="title">Ticket</h1>
      <b-button
        class="btn-filter"
        v-if="items.length > 0"
        @click="openSideBarTicket"
      >
        Create
      </b-button>
    </div>
    <div class="sec2">
      <TableTicket
        :items="itemsTicket"
        :fields="fieldsTicket"
        :isBusy="isBusyTicket"
        :rows="rowsTicket"
        :filter="filterTicket"
        :previewOrPublish="previewOrPublish"
        :pageOptions="pageOptionsTicket"
        @filterPage="filterPageTicket"
        @editTicket="editTicket"
        @removeTicket="openModalConfirm($event, 'ticket')"
      />
      <SideBarFilter
        ref="sidebarTicket"
        title="Ticket"
        :textSubmit="formTicket?.ticketId ? 'Update' : 'Create'"
        :filter="filter"
        :hideStatusFilter="false"
        :hideSearchBar="true"
        @searchAll="insertEventTicket"
        @clearForm="clearFormTicket"
        handleSideBar
        hideFilter
        :isLoading="isLoading"
        @clearLoading="isLoading = false"
      >
        <template v-slot:filter-option>
          <div class="my-3">
            <div class="list-lang">
              <div
                v-for="(item, index) in formTicket.product"
                :key="index"
                class="box-lang"
                :class="indexLang == index && 'active'"
                @click="indexLang = index"
              >
                {{ item.nation }}
              </div>
            </div>
          </div>
          <div class="my-3">
            <InputText
              v-model="formTicket.product[indexLang].name"
              class="input"
              :textFloat="`Ticket Name ( ${formTicket.product[indexLang].nation} )`"
              :placeholder="`Ticket Name`"
              type="text"
              name="text"
              :isRequired="indexLang == 0"
              :isValidate="$v.formTicket.product.$each[indexLang].$error"
              :v="$v.formTicket.product.$each[indexLang].name"
            />
          </div>
          <div class="my-3">
            <InputSelect
              title="Zone Name"
              name="type"
              isRequired
              class="mt-2"
              v-model="formTicket.eventZoneId"
              valueField="id"
              textField="name"
              :options="optionTicketZone"
              @onDataChange="selectZoneId($event, 1)"
              :disabled="previewOrPublish == 1 && formTicket?.ticketId"
              :isValidate="$v.formTicket.eventZoneId.$error"
              :v="$v.formTicket.eventZoneId"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="null" disabled>
                  -- Select Zone --
                </b-form-select-option>
              </template>
            </InputSelect>
          </div>
          <div class="my-3">
            <InputSelect
              title="Ticket Class"
              name="type"
              isRequired
              class="mt-2"
              v-model="formTicket.ticketClassId"
              valueField="id"
              textField="name"
              :options="optionTicketClass"
              @onDataChange="selectTicket($event, 1)"
              :isValidate="$v.formTicket.ticketClassId.$error"
              :v="$v.formTicket.ticketClassId"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="null" disabled>
                  -- Select Ticket --
                </b-form-select-option>
              </template>
            </InputSelect>
          </div>
          <div class="my-3">
            <InputText
              v-model="formTicket.quantity"
              class="input"
              textFloat="Quantity"
              placeholder="0"
              type="number"
              name="number"
              isRequired
              :isValidate="$v.formTicket.quantity.$error"
              :v="$v.formTicket.quantity"
            />
          </div>
          <div class="my-3">
            <InputText
              v-model="formTicket.price"
              class="input"
              textFloat="Price"
              placeholder="0"
              type="number"
              name="number"
              isRequired
              :isValidate="$v.formTicket.price.$error"
              :v="$v.formTicket.price"
            />
          </div>
          <!-- <div class="mt-3 mb-3">
            <b-form-checkbox
              v-model="form.isActive"
              switch
              :value="0"
              :unchecked-value="1"
            >
              Active
            </b-form-checkbox>
          </div> -->
        </template>
      </SideBarFilter>
    </div>
  </div>
</template>

<script>
import TableZone from "../component/tap2/TableZone.vue";
import TableTicket from "../component/tap2/TableTicket.vue";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  props: {
    listLang: {
      type: Array,
      default: [],
    },
    previewOrPublish: {
      type: Number,
      default: [],
    },
  },
  components: {
    TableZone,
    TableTicket,
  },
  data() {
    return {
      isEdit: false,

      // state zone
      form: {
        name: "",
        quantity: null,
        isActive: 1,
      },

      // state table zone
      items: [],
      rows: 0,
      filter: {
        filter: null,
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      },
      fields: [
        {
          key: "name",
          label: "Name",
          class: "w-50px text-nowrap",
        },
        {
          key: "quantity",
          label: "Quality",
          class: "w-50px text-nowrap",
        },
        // {
        //   key: "isActive",
        //   label: "Status",
        //   class: "w-50px text-nowrap",
        // },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      isBusy: false,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // state ticket
      formTicket: {
        ticketTypeId: 1,
        productTypeId: 1,
        ticketClassId: null,
        eventZoneId: null,
        quantity: null,
        price: null,
        product: [
          {
            languageId: "",
            name: "",
            nation: "",
          },
        ],
      },

      indexLang: 0,

      // state options ticket
      optionTicketZone: [],
      optionTicketClass: [],

      // state table ticket
      itemsTicket: [],
      rowsTicket: 0,
      filterTicket: {
        filter: null,
        keyword: "",
        skip: 1,
        take: 10,
        page: 1,
      },
      fieldsTicket: [
        {
          key: "productName",
          label: "Name",
          class: "w-50px text-nowrap",
        },
        {
          key: "eventZoneName",
          label: "Zone",
          class: "w-50px text-nowrap",
        },
        {
          key: "quantity",
          label: "Quality",
          class: "w-50px text-nowrap",
        },
        {
          key: "price",
          label: "Price",
          class: "w-50px text-nowrap",
        },
        // {
        //   key: "isDeleted",
        //   label: "Status",
        //   class: "w-50px text-nowrap",
        // },
        { key: "actions", label: "Action", class: "w-50px text-nowrap" },
      ],
      isBusyTicket: false,
      pageOptionsTicket: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },

      // state delete
      keepRemove: null,

      // state togggle
      isLoading: false,
    };
  },
  validations() {
    const form = {
      name: { required },
      quantity: { required },
    };

    const formTicket = {
      quantity: { required },
      price: { required },
      eventZoneId: { required },
      ticketClassId: { required },
      product: {
        $each: {
          name: {
            required: requiredIf(() => {
              return (
                this.formTicket.product[0].name == "" && this.indexLang == 0
              );
            }),
          },
        },
      },
    };

    return {
      form,
      formTicket,
    };
  },
  mounted() {
    this.getZone();
    this.getTicket();

    // option
    this.getOptionTicketClass();
  },
  methods: {
    async getZone() {
      const getData = await this.$services.event.getZone(
        this.$route.params.id,
        this.filter.skip,
        this.filter.take
      );

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;
      }
    },

    async getTicket() {
      const getData = await this.$services.event.getTicket(
        this.$route.params.id,
        this.filterTicket.skip,
        this.filterTicket.take
      );

      if (getData.result == 1) {
        this.itemsTicket = getData.detail.data;
        this.rowsTicket = getData.detail.count;
      }
    },

    async insertEventZone() {
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }

      // check edit

      if (this.form.id) {
        this.updateZone();
        return;
      }

      this.isLoading = true;

      const insertzone = await this.$services.event.insertEventZone(
        this.$route.params.id,
        this.form
      );

      if (insertzone.result == 1) {
        this.getZone();
      }
      this.$refs.sidebarZone.hide();
      this.openModalAlertText(insertzone.detail);
    },

    async insertEventTicket() {
      this.$v.formTicket.$touch();

      if (!this.$v.formTicket.product.$each[0].$model.name) {
        this.indexLang = 0;
        return;
      }

      if (this.$v.formTicket.$error) {
        return;
      }

      if (this.formTicket?.ticketId) {
        // edit ticket
        this.updateEventTicket();
        return;
      }

      this.isLoading = true;

      const insertticket = await this.$services.event.insertEventTicket(
        this.$route.params.id,
        this.formTicket
      );

      if (insertticket.result == 1) {
        this.getTicket();

        this.$emit("reloadTicket");
      }
      this.closeSideBarTicket();
      this.openModalAlertText(insertticket.message);
    },

    async updateEventTicket() {
      const update = await this.$services.event.updateEventTicket(
        this.$route.params.id,
        this.formTicket.ticketId,
        this.formTicket
      );

      if (update.result == 1) {
        this.getTicket();

        this.$emit("reloadTicket");
      }
      this.closeSideBarTicket();
      this.openModalAlertText(update.detail);
    },

    async updateZone() {
      const newBody = { ...this.form };

      delete newBody.id;

      const updatezone = await this.$services.event.updateEventZone(
        this.$route.params.id,
        this.form.id,
        newBody
      );

      if (updatezone.result == 1) {
        this.getZone();
      }
      this.closeSideBarZone();
      this.openModalAlertText(updatezone.detail);
    },

    async editZone(obj) {
      let { id } = obj;

      const getData = await this.$services.event.getEventZoneById(
        this.$route.params.id,
        id
      );

      if (getData.result == 1) {
        this.form = getData.detail;

        this.openSideBarZone();
      }
    },

    async removeZone() {
      let { id } = this.keepRemove;

      const remove = await this.$services.event.removeEventZone(
        this.$route.params.id,
        id
      );

      if (remove.result == 1) {
        this.getZone();
      }
      this.openModalAlertText(remove.detail);
    },

    async editTicket(obj) {
      let { ticketId } = obj;

      const getData = await this.$services.event.getEventTicketById(
        this.$route.params.id,
        ticketId
      );

      if (getData.result == 1) {
        getData.detail.product = getData.detail.product.map((x) => {
          const langInfo = this.listLang.find(
            (y) => y.languageId == x.languageId
          );

          if (langInfo) {
            const { nation } = langInfo;

            return {
              ...x,
              nation: nation,
            };
          } else {
            console.error(`Language not found for languageId: ${x.languageId}`);
            return {
              ...x,
              nation: "na",
            };
          }
        });

        await this.getOptionEventZone();

        this.formTicket = getData.detail;
        this.$emit("reloadTicket");

        this.$refs.sidebarTicket.show();
      }
    },

    async removeTicket() {
      let { ticketId } = this.keepRemove;

      const removeticket = await this.$services.event.removeEventTicketId(
        this.$route.params.id,
        ticketId
      );

      if (removeticket.result == 1) {
        this.getTicket();
        this.$emit("reloadTicket");
      }
      this.openModalAlertText(removeticket.detail.message);
    },

    async getOptionEventZone() {
      const getData = await this.$services.master.getOptionEventZone(
        this.$route.params.id
      );

      if (getData.result == 1) {
        this.optionTicketZone = getData.detail;
      }
    },

    async getOptionTicketClass() {
      const getData = await this.$services.master.getOptionTicketClass();

      if (getData.result == 1) {
        this.optionTicketClass = getData.detail;
      }
    },

    openModalConfirm(obj, type) {
      this.keepRemove = obj;

      let data = {
        name: type == "zone" ? obj.name : obj.productName,
        type,
      };

      this.$emit("openModalConfirm", data);
    },

    selectZoneId(val, index) {
      this.formTicket.eventZoneId = val;
    },

    selectTicket(val, index) {
      this.formTicket.ticketClassId = val;
    },

    openModalAlertText(text) {
      this.$emit("openModalAlertText", text);
    },

    clearForm() {
      // reset validate

      this.$v.form.$reset();

      this.form = {
        name: "",
        quantity: null,
        isActive: 1,
      };
    },

    clearFormTicket() {
      this.indexLang = 0;

      this.formTicket = {
        ticketTypeId: 1,
        productTypeId: 1,
        productName: "",
        eventZoneId: null,
        quantity: null,
        price: null,
        ticketClassId: null,
        product: [
          {
            languageId: "",
            name: "",
            nation: "",
          },
        ],
      };

      this.$v.formTicket.$reset();
    },

    filterPage(obj) {
      let { page } = obj;
      this.filter.skip = page;

      this.getZone();
    },

    filterPageTicket(obj) {
      let { page } = obj;
      this.filterTicket.skip = page;

      this.getTicket();
    },
    mapDataTicketLanguage() {
      new Promise((resolve, reject) => {
        const closeListLang = [...this.listLang];

        this.formTicket.product = closeListLang.map((x) => {
          return {
            languageId: x.languageId,
            name: "",
            nation: x.nation,
          };
        });

        resolve(true);
      });
    },
    openSideBarZone() {
      this.$refs.sidebarZone.show();
    },
    closeSideBarZone() {
      this.$refs.sidebarZone.hide();
    },
    async openSideBarTicket() {
      await this.mapDataTicketLanguage();
      this.getOptionEventZone();

      this.$refs.sidebarTicket.show();
    },
    closeSideBarTicket() {
      this.$refs.sidebarTicket.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.tap2 {
  background: white;
  padding: 15px;
  min-height: 60vh;

  .title {
    font-size: 18px;
  }

  .div-divide {
    border-bottom: 1px solid darkgray;
    padding: 10px 0px;
  }

  .header-title {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
  }

  .list-lang {
    display: flex;
    justify-content: right;

    .box-lang {
      padding: 5px 10px;
      margin-right: 5px;
      border: 1px solid black;
      cursor: pointer;

      &.active {
        background: var(--secondary-color);
        color: white;
      }
    }
  }
}

// ! Override
.btn-filter {
  width: 100%;
  max-width: 120px;
}
</style>
