<template>
  <div class="event-setting-detail">
    <div class="header-event">
      <h1>Event Setting</h1>

      <b-dropdown v-if="selectLang" :text="selectLang.name" class="m-md-2">
        <b-dropdown-item
          v-for="(item, index) in listLang"
          :key="index"
          @click="changeLanguage(item, index)"
        >
          {{ item.name }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="body">
      <div class="tab">
        <div
          v-for="(item, index) in menuTap"
          :key="index"
          class="list-tap"
          :class="handleClassTap(item.id)"
          @click="tap = item.id"
        >
          <p>
            {{ item.text }}
            <b-icon
              class="icon-error"
              icon="exclamation-circle"
              v-if="item.error"
            ></b-icon>
          </p>
        </div>
      </div>
      <div v-if="selectLang && eventData">
        <Tap1
          ref="tap1"
          :data="eventData.event"
          :selectLang="selectLang"
          v-show="tap == 1"
          :v="$v"
        />

        <Tap2
          ref="tap2"
          v-show="tap == 2"
          :listLang="listLang"
          :previewOrPublish="eventData.event.previewOrPublish"
          @openModalAlertText="openModalAlertText"
          @openModalConfirm="openModalConfirm"
          @reloadTicket="reloadTicket"
        />

        <Tap3
          ref="tap3"
          v-show="tap == 3"
          :previewOrPublish="eventData.event.previewOrPublish"
          @openModalAlertText="openModalAlertText"
        />

        <Tap4
          ref="tap4"
          v-show="tap == 4"
          @openModalAlertText="openModalAlertText"
        />

        <Tap5
          ref="tap5"
          v-show="tap == 5"
          @openModalAlertText="openModalAlertText"
          @openModalConfirm="openModalConfirm"
        />

        <Tap6
          ref="tap6"
          :data="eventData.additionalData"
          :selectLang="selectLang"
          :v="$v"
          v-show="tap == 6"
          @openModalAlertText="openModalAlertText"
          @removeItem="removeItem"
        />
      </div>
      <!-- <div class="publish-event">
        <b-form-checkbox
          v-model="eventData.event.previewOrPublish"
          switch
          :value="1"
          :unchecked-value="0"
        >
          Publist Event
        </b-form-checkbox>
      </div> -->

      <div
        class="tw-sticky tw-bottom-0 tw-bg-white tw-z-40 tw-w-full tw-h-12 tw-px-3"
      >
        <div class="footer-event-setting">
          <b-button class="btn-filter cancel" @click="handleBack">
            Cancel
          </b-button>
          <b-button
            class="btn-filter save"
            :disabled="isLoading"
            @click="onSaveEvent"
          >
            Save
            <b-spinner
              class="m-0 ml-1"
              label="Spinning"
              small
              v-if="isLoading"
            ></b-spinner>
          </b-button>
        </div>
      </div>
    </div>
    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />
    <ModalConfirmDelete :nameItem="nameItem" @confirm="confirm" />
    <ModalPublistEvent
      :textModal="textModal"
      @updatePreviewOrPublish="updatePreviewOrPublish"
    />
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";

import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import ModalConfirmDelete from "@/components/modal/ModalConfirmDelete.vue";
import ModalPublistEvent from "./component/modal/ModalPublistEvent.vue";

import Tap1 from "./component/tap1.vue";
import Tap2 from "./component/tap2.vue";
import Tap3 from "./component/tap3.vue";
import Tap4 from "./component/tap4.vue";
import Tap5 from "./component/tap5.vue";
import Tap6 from "./component/tap6.vue";

export default {
  components: {
    Tap1,
    Tap2,
    Tap3,
    Tap4,
    Tap5,
    Tap6,
    ModalAlertText,
    ModalConfirmDelete,
    ModalPublistEvent,
  },
  data() {
    return {
      tap: 1,

      menuTap: [
        {
          id: 1,
          text: "Event",
          error: false,
        },
        {
          id: 2,
          text: "Ticket / Zone",
          error: false,
        },
        {
          id: 3,
          text: "Schedule",
          error: false,
        },
        {
          id: 4,
          text: "Customer",
          error: false,
        },
        {
          id: 5,
          text: "Banner",
          error: false,
        },
        {
          id: 6,
          text: "Additional Data",
          error: false,
        },
      ],

      listLang: [],

      // state event
      eventData: null,
      selectLang: null,

      // state modal
      textModal: "",
      arrStatus: [],

      nameItem: "",
      typeRemove: "",

      // state toggle
      isLoading: false,
    };
  },
  validations() {
    return {
      eventData: {
        event: {
          imgUrl: { required },
          locationId: { required },
          venueId: { required },
          roomId: { required },
          urlKey: { required },

          mealCode: {
            required: requiredIf(() => {
              return this.eventData.event.isSend == 1;
            }),
          },

          // maximumDayBeforeReserve: { required },
          // minimumDayBeforeReserve: { required },
          eventName: {
            $each: {
              name: {
                required: requiredIf(() => {
                  const isEng = this.eventData.event.eventName.find(
                    (x) => x.languageId == 2
                  );
                  return !isEng.name && this.selectLang.indexLang == 0;
                }),
              },
            },
          },
          shortDescription: {
            $each: {
              shortDescription: {
                required: requiredIf(() => {
                  const isEng = this.eventData.event.shortDescription.find(
                    (x) => x.languageId == 2
                  );
                  return (
                    !isEng.shortDescription && this.selectLang.indexLang == 0
                  );
                }),
              },
            },
          },
          description: {
            $each: {
              description: {
                required: requiredIf(() => {
                  const isEng = this.eventData.event.description.find(
                    (x) => x.languageId == 2
                  );
                  return !isEng.description && this.selectLang.indexLang == 0;
                }),
              },
            },
          },
          remark: {
            $each: {
              remark: {
                required: requiredIf(() => {
                  const isEng = this.eventData.event.remark.find(
                    (x) => x.languageId == 2
                  );
                  return !isEng.remark && this.selectLang.indexLang == 0;
                }),
              },
            },
          },
        },

        additionalData: {
          $each: {
            additionalLanguageData: {
              $each: {
                topic: {
                  required: requiredIf((res) => {
                    let isEng = false;

                    if (res.languageId == 2 && res.topic == "") {
                      isEng = true;
                    } else {
                      isEng = false;
                    }

                    return isEng && this.selectLang.indexLang == 0;
                  }),
                },
                detail: {
                  required: requiredIf((res) => {
                    let isEng = false;

                    if (res.languageId == 2 && res.detail == "") {
                      isEng = true;
                    } else {
                      isEng = false;
                    }

                    return isEng && this.selectLang.indexLang == 0;
                  }),
                },
              },
            },
          },
        },
      },
    };
  },
  watch: {
    tap(val) {
      if (val != 4) {
        this.$refs.tap4.goCustomerDetail();
      }
    },
  },
  mounted() {
    this.getLanguageOption();
    this.getEventData();
  },
  methods: {
    async getLanguageOption() {
      const getData = await this.$services.master.getLanguageOption();

      if (getData.result == 1) {
        this.listLang = getData.detail.map((x, index) => {
          return {
            ...x,
            indexLang: index,
          };
        });

        this.selectLang = this.listLang[0];
      }
    },

    async getEventData() {
      const getData = await this.$services.event.getEventData(
        this.$route.params.id
      );

      if (getData.result == 1) {
        if (getData.detail.event.locationId == 0) {
          getData.detail.event.locationId = null;
        }

        if (getData.detail.event.venueId == 0) {
          getData.detail.event.venueId = null;
        }

        if (getData.detail.event.roomId == 0) {
          getData.detail.event.roomId = null;
        }

        // if (getData.detail.event.maximumDayBeforeReserve == 0) {
        //   getData.detail.event.maximumDayBeforeReserve = null;
        // }

        // if (getData.detail.event.minimumDayBeforeReserve == 0) {
        //   getData.detail.event.minimumDayBeforeReserve = null;
        // }

        this.eventData = getData.detail;
      }
    },

    async onSaveEvent() {
      const isEng = this.listLang.find((x) => x.languageId == 2);

      this.$v.eventData.$touch();

      if (!this.$v.eventData.event.eventName.$each[0].$model.name) {
        this.selectLang = isEng;
        this.tap = 1;
        return;
      }

      if (
        !this.$v.eventData.event.shortDescription.$each[0].$model
          .shortDescription
      ) {
        this.selectLang = isEng;
        this.tap = 1;
        return;
      }

      if (!this.$v.eventData.event.description.$each[0].$model.description) {
        this.selectLang = isEng;
        this.tap = 1;
        return;
      }

      if (!this.$v.eventData.event.remark.$each[0].$model.remark) {
        this.selectLang = isEng;
        this.tap = 1;
        return;
      }

      // validations tap 6

      if (this.eventData.additionalData.length > 0) {
        const isCheckTopic = this.eventData.additionalData.some((x) => {
          const isCheck2 = x.additionalLanguageData.find((y) => {
            return y.languageId == 2;
          });

          return !isCheck2.topic ? true : false;
        });
        const isCheckDetail = this.eventData.additionalData.some((x) => {
          const isCheck2 = x.additionalLanguageData.find((y) => {
            return y.languageId == 2;
          });

          return !isCheck2.detail ? true : false;
        });

        if (isCheckTopic || isCheckDetail) {
          this.selectLang = isEng;
          this.tap = 6;
        }
      }

      if (this.$v.eventData.$error) {
        return;
      }

      this.eventData.additionalData = this.eventData.additionalData.map((x) => {
        return {
          ...x,
          eventInfoId: String(x.eventInfoId).includes("new")
            ? 0
            : x.eventInfoId,
        };
      });

      if (this.eventData.event.imgShowUrl.includes("https")) {
        this.eventData.event.base64 = null;
      } else {
        this.eventData.event.base64 = {
          base64: this.eventData.event.imgShowUrl,
        };
      }

      this.menuTap.forEach((x) => {
        x.error = false;
      });

      // set date

      if (!this.eventData.event.startTimeDisplay) {
        this.eventData.event.startTimeDisplay = null;
      }
      if (!this.eventData.event.endTimeDisplay) {
        this.eventData.event.endTimeDisplay = null;
      }

      delete this.eventData.event.startReserveDate;
      delete this.eventData.event.endReserveDate;

      // check default

      if (!this.eventData.event.mealCode) {
        this.eventData.event.mealCode = null;
      }

      // convert new isDisplay  to 1

      this.eventData.additionalData = this.eventData.additionalData.map((x) => {
        if (x.isDisplay > 1) {
          x.isDisplay = 1;
        }
        return x;
      });

      // convert sortOrder

      if (!this.eventData.event.sortOrder) {
        this.eventData.event.sortOrder = 0;
      }

      this.isLoading = true;

      const save = await this.$services.event.saveEventData(
        this.$route.params.id,
        this.eventData
      );

      if (save.result == 1) {
        this.tap = 1;

        this.textModal = save.detail;

        if (this.eventData.event.previewOrPublish == 0) {
          this.openModalPublistEvent();
        } else {
          this.openModalAlertText(save.detail);
        }
      } else {
        this.openModalAlertText(save.detail);
      }

      this.getEventData();

      this.isLoading = false;
    },

    async updatePreviewOrPublish(val) {
      this.arrStatus = [];

      const put = await this.$services.event.updatePreviewOrPublish(
        this.$route.params.id,
        val
      );

      if (put.result == 1) {
        if (put.detail.isAllReady == 0) {
          put.detail.tab = put.detail.tab.filter((x) => x.error == true);

          this.arrStatus = put.detail.tab;

          this.menuTap = this.menuTap.map((x) => {
            x.error = put.detail.tab.some((y) => {
              if (y.error && y.id == x.id) {
                return true;
              }
            });

            return x;
          });
        }
      }
      this.openModalAlertText(put.detail.message, "showarr");

      this.getEventData();
    },

    reloadTicket() {
      this.$refs.tap3.getOptionTicket();
    },

    removeItem(obj) {
      let { eventInfoId } = obj;

      const isType = typeof eventInfoId;

      if (isType == "string") {
        this.eventData.additionalData = this.eventData.additionalData.filter(
          (x) => {
            return x.eventInfoId != eventInfoId;
          }
        );
      } else {
        this.eventData.additionalData = this.eventData.additionalData.map(
          (x) => {
            if (x.eventInfoId == eventInfoId) {
              x.isDisplay = 0;
            }

            return x;
          }
        );
      }
    },

    openModalPublistEvent() {
      this.$bvModal.show("modal-publish-event");
    },

    openModalAlertText(text, dataArr) {
      if (!dataArr) {
        this.arrStatus = [];
      }

      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },

    openModalConfirm(data) {
      let { name, type } = data;

      this.nameItem = name;
      this.typeRemove = type;

      this.$bvModal.show("modal-confirm-delete");
    },

    closeModalConfirm() {
      this.$bvModal.hide("modal-confirm-delete");
    },

    confirm() {
      switch (this.typeRemove) {
        case "zone":
          this.$refs.tap2.removeZone();
          this.closeModalConfirm();

          break;

        case "ticket":
          this.$refs.tap2.removeTicket();
          this.closeModalConfirm();

          break;

        case "banner":
          this.$refs.tap5.removeBanner();
          this.closeModalConfirm();

          break;

        default:
          console.log(false);
          break;
      }
    },

    changeLanguage(obj, index) {
      this.selectLang = { ...obj, indexLang: index };
    },

    handleClassTap(index) {
      let newClass = "";

      if (index == this.tap) {
        newClass = `${newClass} active`;
      }

      return newClass;
    },

    handleBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.event-setting-detail {
  .header-event {
    display: flex;
    justify-content: space-between;
  }

  .body {
    margin-top: 10px;

    .tab {
      display: flex;
      background: white;

      .list-tap {
        width: 100%;
        max-width: 140px;
        padding: 8px 0px;
        text-align: center;
        cursor: pointer;
        border-bottom: 1px solid gray;

        &.active {
          border-bottom: 2px solid var(--secondary-color);
        }

        p {
          margin: 0;
          border-right: 1px solid gainsboro;
        }

        .icon-error {
          color: red;
        }
      }
    }

    // .publish-event {
    //   background: white;
    //   padding: 15px;
    // }
  }

  .footer-event-setting {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
}

// ! override
.btn-filter {
  width: 100%;
  max-width: 150px;

  &.cancel {
    border: none;
    background: #606060;
    color: white !important;
  }

  &.save {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    border: none;
    color: white !important;
  }

  &.save:hover {
    background: #f8f8f8;
    color: var(--primary-color) !important;
  }
  &.cancel:hover {
    background: #f8f8f8;
    color: var(--primary-color) !important;
  }
}
</style>
