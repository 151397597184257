<template>
  <div class="tap1">
    <div class="sec1">
      <b-row>
        <b-col cols="6">
          <InputText
            v-model="filterLang(data.eventName).name"
            textFloat="Name"
            placeholder="Name"
            type="text"
            name="text"
            :isRequired="selectLang.indexLang == 0 && true"
            :isValidate="
              v.eventData.event.eventName.$each[selectLang.indexLang].name
                .$error
            "
            :v="v.eventData.event.eventName.$each[selectLang.indexLang].name"
            :selectLang="selectLang"
          />
        </b-col>
        <b-col cols="6">
          <InputText
            v-model="data.urlKey"
            textFloat="Url"
            placeholder="Url"
            type="text"
            name="textUrl"
            isRequired
            :isValidate="v.eventData.event.urlKey.$error"
            :v="v.eventData.event.urlKey"
          />
          <!-- :isRequired="selectLang.indexLang == 0 && true"
             -->
        </b-col>
        <b-col cols="6">
          <UploadFile
            textFloat="Cover Image"
            placeholder="Please Choose File"
            format="image"
            name="file"
            text="*Please upload only .png .jpg image width ratio 16:10 less than 10 MB"
            accept="image/*"
            :isRequired="selectLang.indexLang == 0 && true"
            @onFileChange="onFileChange"
            :fileName="data.imgUrl"
            v-model="image"
            id="uploadfile"
            :v="v.eventData.event.imgUrl"
          />
        </b-col>
        <b-col cols="6"></b-col>
        <b-col cols="6">
          <div
            class="render-image"
            :style="`background-image:url(${data.imgShowUrl})`"
          ></div>
        </b-col>
      </b-row>
      <div>
        <InputTextArea
          v-model="filterLang(data.shortDescription).shortDescription"
          textFloat="Short Description"
          type="text"
          class="mt-3 custom-input"
          placeholder=""
          rows="4"
          :isRequired="selectLang.indexLang == 0 && true"
          :isValidate="
            v.eventData.event.shortDescription.$each[selectLang.indexLang]
              .$error
          "
          :v="
            v.eventData.event.shortDescription.$each[selectLang.indexLang]
              .shortDescription
          "
          :selectLang="selectLang"
        />
      </div>

      <b-row>
        <b-col cols="6">
          <InputSelect
            v-model="data.locationId"
            :options="optionLocation"
            title="Location"
            class="mt-2"
            valueField="locationId"
            textField="name"
            :isRequired="selectLang.indexLang == 0 && true"
            @input="getVenueOption"
            :isValidate="v.eventData.event.locationId.$error"
            :v="v.eventData.event.locationId"
          >
            <!-- :disabled="!dataPayment.canEditFlag"
          @onDataChange="changeBank" -->
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled>
                กรุณาเลือก
              </b-form-select-option>
            </template>
          </InputSelect>
        </b-col>

        <b-col cols="6">
          <InputSelect
            v-model="data.venueId"
            :options="optionVenue"
            title="Venue (Google Map)"
            class="mt-2"
            valueField="venueId"
            textField="name"
            :isRequired="selectLang.indexLang == 0 && true"
            :isValidate="v.eventData.event.venueId.$error"
            :v="v.eventData.event.venueId"
            @input="getRoomOption"
          >
            <!-- :disabled="!dataPayment.canEditFlag"
        
          @onDataChange="changeBank" -->
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled>
                กรุณาเลือก
              </b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
        <b-col cols="6">
          <InputSelect
            v-model="data.roomId"
            :options="optionRoom"
            title="Room"
            class="mt-2"
            valueField="roomId"
            textField="name"
            :isRequired="selectLang.indexLang == 0 && true"
            :isValidate="v.eventData.event.roomId.$error"
            :v="v.eventData.event.roomId"
          >
            <!-- :disabled="!dataPayment.canEditFlag"
          :isValidate="
            $v.dataPayment.bankTransferList.$each[selectBankIndex].bankAccountId
              .$error
          "
          :v="
            $v.dataPayment.bankTransferList.$each[selectBankIndex].bankAccountId
          "
          @onDataChange="changeBank" -->
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled>
                กรุณาเลือก
              </b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
        <b-col cols="6"> </b-col>
        <b-col cols="6">
          <b-form-checkbox
            v-model="data.isSend"
            :value="1"
            :unchecked-value="0"
          >
            Send Ticketing
          </b-form-checkbox>
        </b-col>
        <b-col cols="6"></b-col>
        <b-col cols="6">
          <InputText
            v-model="data.mealCode"
            textFloat="MealCode"
            placeholder="MealCode"
            type="text"
            name="MealCode"
            :isRequired="data.isSend == 1"
            :isValidate="v.eventData.event.mealCode.$error"
            :v="v.eventData.event.mealCode"
          />
        </b-col>
        <b-col cols="6">
          <InputText
            v-model="data.sortOrder"
            textFloat="SortOrder"
            placeholder="SortOrder"
            type="number"
            name="SortOrderid"
          />
        </b-col>
      </b-row>
    </div>
    <div class="sec2">
      <div class="header">Condition</div>
      <div class="body">
        <InputText
          v-model="data.maximumDayBeforeReserve"
          class="w-100"
          textFloat="Maximum days booking (Day)"
          placeholder="0"
          type="number"
          name="number"
        />
        <!-- :isRequired="selectLang.indexLang == 0 && true"
          :isValidate="v.eventData.event.maximumDayBeforeReserve.$error"
          :v="v.eventData.event.maximumDayBeforeReserve" -->
        <div class="mx-1"></div>
        <InputText
          v-model="data.minimumDayBeforeReserve"
          class="w-100"
          textFloat="Minimum days required before booking (Day)"
          placeholder="0"
          type="number"
          name="number"
        />
        <!-- :isRequired="selectLang.indexLang == 0 && true"
          :isValidate="v.eventData.event.minimumDayBeforeReserve.$error"
          :v="v.eventData.event.minimumDayBeforeReserve" -->
      </div>
    </div>
    <div class="sec3">
      <div class="header">Description</div>
      <div class="body">
        <TextEditorsTiny
          :value="filterLang(data.description).description"
          textFloat="Description"
          :rows="4"
          :name="'description_' + 1"
          placeholder="Type something..."
          @onDataChange="
            (val) => (filterLang(data.description).description = val)
          "
          :isRequired="selectLang.indexLang == 0 && true"
          :v="
            v.eventData.event.description.$each[selectLang.indexLang]
              .description
          "
          :selectLang="selectLang"
        />
        <!-- :isValidate="v.eventData.event.shortDescription.$each[0].$error" -->
        <!-- :value="form.description"
          :v="v.form.description"
           -->
      </div>
    </div>
    <div class="sec4">
      <div class="header">Remark</div>
      <div class="body">
        <InputTextArea
          v-model="filterLang(data.remark).remark"
          textFloat="Remark"
          type="text"
          class="mb-3 custom-input"
          placeholder=""
          rows="4"
          :isRequired="selectLang.indexLang == 0 && true"
          :isValidate="
            v.eventData.event.remark.$each[selectLang.indexLang].$error
          "
          :v="v.eventData.event.remark.$each[selectLang.indexLang].remark"
          :selectLang="selectLang"
        />
        <b-row>
          <b-col cols="6">
            <div>
              <div class="font-weight-bold mb-2">Start Date (DD/MM/YYYY)</div>
              <div :class="['input-container']">
                <datetime
                  v-model="data.startTimeDisplay"
                  :input-style="styleDatetime"
                  format="dd/MM/yyyy"
                  value-zone="local"
                  placeholder="Please Select Date"
                  ref="createStartDate"
                >
                  <!-- v-model="configFilter.createdTime.endTime"
                  :disabled="!configFilter.createdTime.startTime"
                  :max-datetime="maxDate"
                  :min-datetime="minDateCreate" -->
                </datetime>
                <div
                  class="icon-primary text-right"
                  @click="$refs.createStartDate.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="pointer g-text-gold"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div>
              <div class="font-weight-bold mb-2">End Date (DD/MM/YYYY)</div>
              <div :class="['input-container']">
                <datetime
                  v-model="data.endTimeDisplay"
                  :input-style="styleDatetime"
                  format="dd/MM/yyyy"
                  value-zone="local"
                  placeholder="Please Select Date"
                  ref="createEndDate"
                >
                  <!-- v-model="configFilter.createdTime.endTime"
                  :disabled="!configFilter.createdTime.startTime"
                  :max-datetime="maxDate"
                  :min-datetime="minDateCreate" -->
                </datetime>
                <div
                  class="icon-primary text-right"
                  @click="$refs.createEndDate.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="pointer g-text-gold"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
    selectLang: {
      type: Object,
      default: null,
    },
    v: {
      type: Object,
      require: false,
    },
  },
  components: {
    UploadFile,
  },

  data() {
    return {
      image: "",
      filename: "",

      form: {
        locationId: null,
        venueId: null,
        roomId: null,
      },

      //   state select
      option: [],

      // state datetime
      styleDatetime: {
        width: "100%",
        border: "none",
      },

      // state option
      optionLocation: [],
      optionVenue: [],
      optionRoom: [],

      // state time out
      delay: null,
    };
  },
  watch: {
    "data.locationId"() {
      this.data.venueId = null;
    },
    "data.venueId"() {
      this.data.roomId = null;
    },
    "data.urlKey"(val) {
      if (this.delay) {
        clearTimeout(this.delay);
      }
      this.delay = setTimeout(() => {
        this.data.urlKey = val.replace(/[^a-zA-Z0-9_-]/g, "");
      }, 500);
    },
  },
  mounted() {
    this.getLocationOption();
    this.getVenueOption();
    this.getRoomOption();
  },
  methods: {
    async getLocationOption() {
      const getData = await this.$services.master.getLocationOption();

      if (getData.result == 1) {
        this.optionLocation = getData.detail;
      }
    },
    async getVenueOption() {
      const getData = await this.$services.master.getVenueOption(
        this.data.locationId == null ? 0 : this.data.locationId
      );

      if (getData.result == 1) {
        this.optionVenue = getData.detail;
      }
    },
    async getRoomOption() {
      const getData = await this.$services.master.getRoomOption(
        this.data.venueId == null ? 0 : this.data.venueId
      );

      if (getData.result == 1) {
        this.optionRoom = getData.detail;
      }
    },

    onFileChange(file) {
      this.data.imgUrl = file.name;
      this.isDisable = false;

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (result) => {
        this.data.imgShowUrl = result.target.result;
      };
    },

    filterLang(arr) {
      const final = this.$services.utils.fillterLang(
        arr,
        this.selectLang.languageId
      );

      return final;
    },
  },
};
</script>

<style lang="scss" scoped>
.tap1 {
  background: white;
  min-height: 60vh;

  .sec1 {
    padding: 15px;

    .render-image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 300px;
      height: 200px;
      border: 1px dashed gray;
    }
  }

  .sec2 {
    .header {
      padding: 15px;
      background: var(--secondary-color);
      color: var(--font-color);
    }

    .body {
      padding: 15px;
      display: flex;
    }
  }

  .sec3 {
    .header {
      padding: 15px;
      background: var(--secondary-color);
      color: var(--font-color);
    }

    .body {
      padding: 15px;
    }
  }

  .sec4 {
    .header {
      padding: 15px;
      background: var(--secondary-color);
      color: var(--font-color);
    }

    .body {
      padding: 15px;
    }
  }
}
</style>
